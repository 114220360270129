import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { HelperService } from 'src/app/services/helper.service';
import { formatDate } from '@angular/common';
//import Swal from 'sweetalert2';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  sku: string,
  store: string,
  typeup: string
}

interface FiltrosUpdate{
  sku: string,
  baseprice: string,
  listprice: string,
  costprice: string,
  markup: string
}

interface FiltrosUpdateStock{
  sku: string,
  warehouseid: string,
  warehousename: string,
  totalquantity: string,
  reservedquantity: string,
  hasUnlimitedQuantity: string
}

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {

  view: view = {
    title: 'ACTUALIZACION DE STOCK/PRECIO EN TIENDA VTEX',
    isLoading: false,
    _url: `eventos/stock`
  }

  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  total: number = 0;
  FiltrosUpdate: FiltrosUpdate = this.emptyFiltersUpdate();
  FiltrosUpdateStock: FiltrosUpdateStock = this.emptyFiltersUpdateStock();
  resultadosStock: any[] = [];

  //Datos tabla ngx
  resultStockRows: any[] = [];
  resultPriceRows: any[] = [];


  public showButton : boolean = true;
  public showTablePrice : boolean = false;
  public showBtnEdit : boolean = true;
  public showFieldEdit : boolean = false;

  public showTablePriceList : boolean = false;
  public showBtnEditPl : boolean = true;
  public showFieldEditPl : boolean = false;

  public showTableStock : boolean = false;
  public showBtnEditStock : boolean = true;
  public showFieldEditStock : boolean = false;

  constructor(
    private API: ApiService,
    private sidebar: SideBarService,
    private appService:AppService,
    private Helper: HelperService,
    private formBuilder: FormBuilder
  ) { }

  uptypes: any[] = [
    {name: 'Actualizar Inventario', value: 'stock'},
    {name: 'Actualizar Precio Base', value: 'price'},
    {name: 'Actualizar Precio Rebajado', value: 'plist'},
  ];


  upstores: any[] = [
    {name: 'hebqamx - Heb QA', value: 'hebqamx'}
  ];

  formUpStock = this.formBuilder.group({
    sku:['', Validators.required],
    updatestore:['', Validators.required],
    updatetype:['', Validators.required]
  });

  formUpPrice = this.formBuilder.group({
    uppsku:['', Validators.required],
    uppbaseprice:['', Validators.required],
    /*uppmarkup:['', Validators.required],
    upplistprice:['', Validators.required]*/
  });

  formUpPriceList = this.formBuilder.group({
    uppsku:['', Validators.required],
    /*uppbaseprice:['', Validators.required],
    uppmarkup:['', Validators.required],*/
    upplistprice:['', Validators.required]
  });

  ngOnInit(): void {
    this.sidebar.showLoading();

    // Cargar informacion de las tiendas
    this.upstores = this.appService.getVtexStores();
  }

  validForm(event: Event) {
    event.preventDefault();
    if(this.formUpStock.valid) {
      this.getskuinfo();
    } else {
      //alert('Faltan Datos - ERROR -');
      this.appService.getShowModalInfoFormat("FALTA INFORMACION","Debe ingresar todos los datos solicitados.","info");
    }
  }

  validFormPrice(event: Event) {
    event.preventDefault();
    if(this.formUpPrice.valid) {
      this.updateInfoPriceData();
    } else {
      //alert('- ERROR >> Debe ingresar todos los datos solicitados.');
      this.appService.getShowModalInfoFormat("ACTUALIZAR DATOS","PrecioBase - Debe ingresar todos los datos solicitados.","info");
    }
  }

  validFormPriceList(event: Event) {
    event.preventDefault();
    if(this.formUpPriceList.valid) {
      this.updateInfoPriceListData();
    } else {
      //alert('- ERROR >> Debe ingresar todos los datos solicitados.');
      this.appService.getShowModalInfoFormat("ACTUALIZAR DATOS","PrecioRebakado - Debe ingresar todos los datos solicitados.","info");
    }
  }

  // Consultar datos segun operacion
  getskuinfo(): void {

    this.showTableStock = false;
    this.showTablePrice = false;
    this.showTablePriceList = false;
    this.showFieldEdit = false;
    this.showFieldEditStock = false;
    this.showBtnEdit = true;
    this.showBtnEditStock = true;
    this.showFieldEditPl = false;
    this.showBtnEditPl = true;

    let typeOp = this.Filtros.typeup;

    let dataGetStock = {
      gsStore: this.Filtros.store, 
      gsSku: this.Filtros.sku
    };

    if(typeOp == "stock"){
      // Consultar informacion de Inventario
      this.view.isLoading = true;
      this.API.ups_get( dataGetStock ).subscribe((res: any) => {
        this.view.isLoading = false;
        console.log('respuesta de API.ups_get ---');
        console.log(res);
        if(res != null && res != 'undefined' && res.result != null && res.result != 'undefined' && res.result == "ok") {
          //console.log(res.data);
          let dataRes = JSON.parse(res.data);
          //console.log(dataRes);

          if(/*dataRes.skuId == this.Filtros.sku &&*/ dataRes.balance.length>0 ){
            //console.log('mostrar datos STOCK en tabla');
            //console.log(this.resultadosStock);
            this.FiltrosUpdateStock.sku = dataRes.skuId;
            this.showTableStock = true;            
            this.resultStockRows = dataRes.balance;
          }

        } else {
          console.log('ERROR al obtener datos');
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 2500 });
        }

      });

    }else{
      // Consultar informacion de Precios
      this.view.isLoading = true; 
      this.API.upp_get( dataGetStock ).subscribe((res: any) => {
        this.view.isLoading = false;
        //console.log('respuesta de API.upp_get ---');
        //console.log(res);
        if(res != null && res != 'undefined' && res.result != null && res.result != 'undefined' && res.result == "ok") {
          //console.log(res.data);
          try {
            //JSON.parse(text);
              //return true;
              let dataRes = JSON.parse(res.data);
              //console.log(dataRes);
              if(dataRes.itemId != null /*this.Filtros.sku*/ ){
                this.resultPriceRows = [dataRes];
                //console.log('mostrar datos PRICES en tabla');
                this.FiltrosUpdate.sku = dataRes.itemId;
                this.FiltrosUpdate.baseprice = dataRes.basePrice;
                this.FiltrosUpdate.listprice = dataRes.listPrice;
                this.FiltrosUpdate.markup = dataRes.markup;
                
                if(typeOp == "price"){ this.showTablePrice = true; }
                if(typeOp == "plist"){ this.showTablePriceList = true; }
                
              }

          } catch (error) {
            //return false;
            console.log('ERROR al obtener datos. No se encontró informacion con los datos proporcionados.');
            //alert('No se encontró informacion con los datos proporcionados.');
            this.appService.getShowModalInfoFormat("Sin Información","No se encontró información con los datos proporcionados.","info");
            //this.Helper.show('No se encontró informacion con los datos proporcionados.', { classname: `bg-danger text-light`, delay: 2500 });
        }

        } else {
          console.log('ERROR al obtener datos');
          this.appService.getShowModalInfoFormat("ERROR RESPONSE","Ocurrio un error al obtener los datos.","error");
          //this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 2500 });
        }
      });
    }
  }

  emptyFilters(): Filtros {
    return {
      sku: '',
      store: '',
      typeup: ''
    };
  }
  emptyFiltersUpdate(): FiltrosUpdate {
    return {
      sku: '',
      baseprice: '',
      listprice: '',
      costprice: '',
      markup: '',
    };
  }
  emptyFiltersUpdateStock(): FiltrosUpdateStock {
    return {
      sku: '',
      warehouseid: '',
      warehousename: '',
      totalquantity: '',
      reservedquantity: '',
      hasUnlimitedQuantity: ''
    };
  }

  
  enableUpdate(): void {
    this.showFieldEdit = true;
    this.showBtnEdit = false;
  }
  enableUpdatePL(): void {
    this.showFieldEditPl = true;
    this.showBtnEditPl = false;
  }
  enableUpdateStock(event: any): void {
    //console.log('-- Habilitar btn actualizar STOCK:');
    let fullqty = event.srcElement!.value + '_rusqty';
    //let fullreserve = event.srcElement!.value + '_rusreserveqty';
    //let fullunlimit = event.srcElement!.value + '_rusunlimitqty';
    let fullbtnupdate = event.srcElement!.value + '_btnupdatestock';
    let fullbtnedit = event.srcElement!.value + '_btneditstock';
    
    if(document.getElementById(fullqty) != null){
      document.getElementById(fullqty)!.style.display="block";
      //document.getElementById(fullreserve)!.style.display="block";
      //document.getElementById(fullunlimit)!.style.display="block";
      document.getElementById(fullbtnupdate)!.style.display="block";
      document.getElementById(fullbtnedit)!.style.display="none";
    }
      
  }
  
  updateStockItem(event: any): void {
    //console.log('-- ACTUALIZAR STOCK:');
    let fullWh = event.srcElement.value;
    let fullqty = event.srcElement!.value + '_rusqty';
    //let fullreserve = event.srcElement!.value + '_rusreserveqty';
    //let fullunlimit = event.srcElement!.value + '_rusunlimitqty';

    //console.log('fullqty: '+fullqty);
    var sup_qty = document.getElementById(fullqty)as HTMLInputElement | null ;
    var valqty = '0';
    if(sup_qty != null){
      valqty = sup_qty?.value;
    }
    
    /*var sup_unlimit = document.getElementById(fullunlimit)as HTMLInputElement | null ;
    var valunlimit = 'false';
    if(sup_unlimit != null){
      valunlimit = sup_unlimit?.value;
    }*/
    
    this.view.isLoading = true;
    var current_date = new Date();
    var valdate = formatDate(current_date,'yyyy-MM-dd',"en-US") + 'T12:00:00';
    //console.log('valdate: '+valdate);
    
    //Object new STOCK data
    const newStockData = {
      unlimitedQuantity: 'false',
      dateUtcOnBalanceSystem: valdate,
      quantity: parseInt(valqty)
    }

    let dataUpdateStock = {
      usStore: this.Filtros.store, 
      usSku: this.Filtros.sku,
      usWarehouse: fullWh,
      usNewStock: newStockData
    };

    this.API.ups_put(dataUpdateStock).subscribe((res: any) => {
      this.view.isLoading = false;
      //console.log(res);
      //alert('Datos Actualizados.');
      this.appService.getShowToastFormat("Datos Actualizados.","success");
      //this.Helper.show('Datos actualizados', { classname: `bg-success text-light`, delay: 2500 });
      this.getskuinfo();
    }, (e: any) => {
      console.warn('Actualizar STOCK -> ', e);
    });
      
  }

  updateInfoPriceData():void {
    //console.log('==== ACTUALIZAR INFO PRECIO ===');
    this.view.isLoading = true;
    
    //Object new Price data
    const newPriceData = {
      basePrice: parseFloat(this.FiltrosUpdate.baseprice),
      listPrice: 0, /*parseFloat(this.FiltrosUpdate.listprice),*/
      markup: 0, /*parseInt(this.FiltrosUpdate.markup)*/
    }

    let dataUpdatePrice = {
      upStore: this.Filtros.store, 
      upSku: this.Filtros.sku,
      upNewPrices: newPriceData
    };
    
    this.API.upp_put(dataUpdatePrice).subscribe((res: any) => {
      this.view.isLoading = false;
      //console.log(res);
      //alert('Datos Actualizados.');
      this.appService.getShowToastFormat("Datos Actualizados.","success");
      //this.Helper.show('Datos Actualizados', { classname: `bg-success text-light`, delay: 2500 });
      this.getskuinfo();
    }, (e: any) => {
      console.warn('Actualizar PRICE -> ', e);
    });
  }

  updateInfoPriceListData():void {
    //console.log('==== ACTUALIZAR INFO PRECIO REBAJADO ===');
    this.view.isLoading = true;
    
    //Object new Price data
    const newPriceData = {
      basePrice: parseFloat(this.FiltrosUpdate.baseprice),
      listPrice: parseFloat(this.FiltrosUpdate.listprice),
      markup: 0, /*parseInt(this.FiltrosUpdate.markup)*/
    }

    let dataUpdatePrice = {
      upStore: this.Filtros.store, 
      upSku: this.Filtros.sku,
      upNewPrices: newPriceData
    };
    
    this.API.upp_put(dataUpdatePrice).subscribe((res: any) => {
      this.view.isLoading = false;
      //console.log(res);
      //alert('Datos Actualizados.');
      this.appService.getShowToastFormat("Datos Actualizados.","success");
      //this.Helper.show('Datos Actualizados', { classname: `bg-success text-light`, delay: 2500 });
      this.getskuinfo();
    }, (e: any) => {
      console.warn('Actualizar PRICE -> ', e);
    });
  }
  
}
