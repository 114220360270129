import { Injectable, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { OrmService } from 'src/app/services/orm.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  toasts: any[] = [];
  private _date: Date = new Date();

  constructor(
    private ORM: OrmService,
    private router: Router
  ) { }

  logOut() {
    this.ORM.logout();
    this.router.navigate(['/login']);
  }

  /**
   * ToastService
   */
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
    //this.toasts = [];
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  ngOnDestroy(): void {
      this.toasts.splice(0, this.toasts.length);
  }

  /**
   * DateTime
   */
 
  IntTwoChars(i: any) {
    return (`0${i}`).slice(-2);
  }

  getDateString() {
    let date = this.IntTwoChars(this._date.getDate());
    let month = this.IntTwoChars(this._date.getMonth() + 1);
    let year = this._date.getFullYear();
    return `${year}-${month}-${date}`;
  }
}
