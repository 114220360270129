import { Component, OnInit} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

interface view {
  title: string,
  isLoading: boolean,
  isLoadingInyection: boolean
}

interface itemsModel {
  orderId: null,
  orderTotal: any
}

@Component({
  selector: 'app-push-inyeccion',
  templateUrl: './push-inyeccion.component.html',
  styleUrls: ['./push-inyeccion.component.css']
})
export class PushInyeccionComponent implements OnInit{

itemsData : any[] = [];
globalData : any[] = [];
itemDataRes : any[] = [];
itemsModel : itemsModel = {
  orderId: null,
  orderTotal: 0
}
reorderable = true;
isDisabled = false;
applyFilter = false;
applyFilterData = '';
shippingAmount :number = 0;
view: view = {
  title: 'Push Inyeccion',
  isLoading: false,
  isLoadingInyection: false
}
showButtonSearch : boolean = true;
discountAmount :number = 0;
isCoupon: boolean = false;
couponName:string =  "";  
oldValueQty :string = "0";

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder,
    private appService:AppService
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    //this.getStoreFilterPromo();
  }
  formUpDiscount = this.formBuilder.group({orderId:['']});
  async getPushInyeccion(event: Event) {
    event.preventDefault();
    this.showButtonSearch = false;
    this.view.isLoading = true;
    if (this.formUpDiscount.value.orderId) {
      var data = {orderIdVtex: this.formUpDiscount.value.orderId};
      let dataOrder = JSON.parse(JSON.stringify(data));
      this.API.pushinyeccion(dataOrder).subscribe((data: any) => {
        this.showButtonSearch = true;
        this.view.isLoading = false;
        if (typeof data[0] != "undefined") {
          this.itemsData = Object.values(data[0].json.body.collections[0].order_sections[0].items);
          this.itemDataRes = Object.values(data[0].json.body.collections[0].order_sections[0].items);
          this.globalData = Object.values(data);
          //console.log(this.globalData);
          this.itemsModel.orderTotal = data[0].json.body.collections[0].header.customer.order_total;
          this.shippingAmount = data[0].json.body.collections[0].order_sections[0].shipping.amount;
          let discount = 0;
          let existCoupon = false;
          let coupon = '';
          this.itemsData.filter(function(element: any){
            if (element.discounts.length) {
              existCoupon = true;
              coupon = element.discounts[0].offer_type;
              if (element.discounts[0].offer_type == 'Cupon' || element.discounts[0].offer_type == 'cupon') {
                coupon = element.discounts[0].offer_id;
              }
              
              discount = discount + element.discounts[0].discount_amount;
            } 
          });
          this.discountAmount = discount;
          this.isCoupon = existCoupon;
          this.couponName = coupon;
        } else {
          this.itemsData = [];
          this.itemDataRes = [];
          this.globalData = [];
          this.itemsModel.orderTotal = 0;
          this.isCoupon = false;
          this.couponName = '';
          this.appService.getShowModalInfoFormat("ATENCIÓN","No se encontraron datos con esa orden.","info");
        }
      }, (e: any) => {
        this.showButtonSearch = true;
        this.view.isLoading = false;
        this.isCoupon = false;
        this.couponName = '';
        this.appService.getShowModalInfoFormat("ERROR","Ocurrio un error al momento de obtener la información de la orden, intente nuevamente.","error");
        console.log('getPushInyeccion -> ', e);
      });
    } else {
      this.showButtonSearch = true;
      this.view.isLoading = false;
      this.appService.getShowModalInfoFormat("ATENCIÓN","Debe colocar una número de orden.","warning");
    }
  }

  editSku(sku: any): void {
    if (this.applyFilter) {
      this.itemsData = this.itemDataRes;
    } 
    this.itemsData = this.remove(this.itemsData, sku)
  }

  remove(array: any, sku: any){
    let totalDiscount = 0;
    let newData = array.filter(function(element: any){
      if (element.attributes.sku != sku) {
        totalDiscount = totalDiscount + element.partner_price * element.quantity;
      }
      return element.attributes.sku != sku;
    });
    if (this.discountAmount > totalDiscount) {
        this.appService.getShowModalInfoFormat("ATENCIÓN","El descuento no puede ser mayor al total de la orden, favor de revisar nuevamente.","warning");
        return array;
    } else {
      totalDiscount = Math.abs(totalDiscount - this.discountAmount);
      totalDiscount = totalDiscount  + this.shippingAmount;
      let totalUpdate = totalDiscount.toFixed(2);
      this.itemsModel.orderTotal = totalUpdate;
      this.globalData = this.globalData.filter(function(element: any){
        element['json'].body.collections[0].header.customer.order_total = totalUpdate;
        element['json'].body.collections[0].order_sections[0].ticket.ticket_amount = totalUpdate;
        element['json'].body.collections[0].order_sections[0].ticket.payments[0].amount = totalUpdate;
        element['order_total'] = parseInt(totalUpdate.toString().replace('.', ''));
        element['base_grand_total'] = parseInt(totalUpdate.toString().replace('.', ''));
        element['json'].body.collections[0].order_sections[0].items = element['json'].body.collections[0].order_sections[0].items.filter(function(elements: any){
         return elements.attributes.sku != sku;
        });
        return element;
      });
      this.itemDataRes = newData;
      return newData;
    }     
  }

  changeQty(sku: any, event: Event): void {
    let elementQty = event.currentTarget as HTMLInputElement;
    let valueQty:number = Number(elementQty.value);
    if (elementQty.value == '') {
      this.isDisabled = true;
        this.appService.getShowModalInfoFormat("ATENCIÓN","La cantidad no puede estar vacía, intente nuevamente.","warning");
    } else {
      this.isDisabled = false;
      let newTotal = 0;
      if (this.applyFilter) {
        this.itemsData = this.itemDataRes;
      } 
      let newData = this.itemsData.filter(function(element: any){
        if (sku == element.attributes.sku){
          element.ticket.quantity = valueQty;
          element.available_quantity = valueQty;
        }
        newTotal = newTotal + (element.partner_price * element.quantity);
        
      });
      if (this.discountAmount > newTotal) {
        this.appService.getShowModalInfoFormat("ATENCIÓN","El descuento no puede ser mayor al total de la orden, favor de revisar nuevamente.","warning");
        elementQty.value = this.oldValueQty;
      } else {
        this.oldValueQty = elementQty.value;
        newTotal = Math.abs(newTotal - this.discountAmount);
        newTotal = newTotal  + this.shippingAmount;
        let total = newTotal.toFixed(2);
        this.itemsModel.orderTotal = total;
        let getNewData = this.itemsData;
        this.globalData.filter(function(element: any){
          element['json'].body.collections[0].header.customer.order_total = total;
          element['json'].body.collections[0].order_sections[0].ticket.ticket_amount = total;
          element['json'].body.collections[0].order_sections[0].ticket.payments[0].amount = total;
          element['json'].body.collections[0].order_sections[0].items = getNewData;
          element['order_total'] = parseInt(total.toString().replace('.', ''));
          element['base_grand_total'] = parseInt(total.toString().replace('.', ''));
        });
        //this.itemDataRes = this.globalData;
        if (this.applyFilter) {
          let dataFilter = this.applyFilterData;
          let temp = this.itemsData.filter(function(d: any) {
            return JSON.stringify(d).toLowerCase().indexOf(dataFilter) !== -1 || !dataFilter;
          });
          this.itemsData = temp;
        }
      }
    }
  }

  getOldValue(event: Event): void {
    let elementQty = event.currentTarget as HTMLInputElement;
    this.oldValueQty = elementQty.value;
  }

  updateFilter(event : any) {
    let val = event.target.value.toLowerCase();
    this.applyFilter = false;
    this.applyFilterData = '';
    if (event.target.value) {
      let temp = this.itemsData.filter(function(d: any) {
        return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.applyFilter = true;
      this.itemsData = temp;
      this.applyFilterData = val;
    } else {
      this.itemsData = this.itemDataRes;
    }
  }

  processInyection(): void {
    this.view.isLoadingInyection = true;
    this.API.createPushInyeccion(this.globalData).subscribe((data: any) => {
      if (data.success == true) {
        this.formUpDiscount.reset();
        this.appService.getShowToastFormat(data.result,"success");
        this.itemsData = [];
        this.itemDataRes = [];
        this.globalData = [];
        this.itemsModel.orderTotal = 0;
        this.view.isLoadingInyection = false;
      } else {
        this.Helper.show(data.result, { classname: `bg-danger text-light`, delay: 3500 }); 
        this.appService.getShowModalInfoFormat("ERROR",data.result,"error");
        console.log(data);
        this.view.isLoadingInyection = false;
      }
    }, (e: any) => {
      console.log('processInyection -> ', e);
      this.view.isLoadingInyection = false;
      this.appService.getShowModalInfoFormat("ERROR","Ocurrio un error al realizar el proceso de inyección, intente nuevamente.","error");
    });
  }
}
