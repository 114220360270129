<div class="container-fluid pt-4 px-4">
  <div class="bg-light rounded-top p-4">
    <div class="card card-primary border border-secondary " >
        <div class="card-header p-3 mb-2 bg-primary text-white">
            <i class="bi bi-arrow-up-circle me-2"></i><span class="mb-4">{{ view.title }}</span>
        </div>
        <div class="card-body">
        <form [formGroup]="formUpDiscount" (ngSubmit)="getPushInyeccion($event)">
          <div class="row">
          <div class="col-sm-9 col-md-6 col-lg-6 col-xl-6">
            <div class="form-floating mb-3">
                <input 
                type="text"
                name="orderId"
                placeholder="No. Orden"
                class="form-control"
                required
                [(ngModel)]="itemsModel.orderId"
                formControlName="orderId"
                />
                    <label for="orderId">No. Orden</label>
                </div>
          </div>
        </div>
        <div class="form-floating">
            <div class="text-sm-end">
                <button *ngIf="showButtonSearch" type="submit" class="btn btn-dark">
                Solicitar Información
                </button>
                <ng-template [ngIf]="view.isLoading" >
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" >
                            <span class="sr-only" id="loading"></span>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        </form>
        </div>
    </div>
  </div>
</div>
<div class="container-fluid pt-4 px-4" *ngIf="itemsModel.orderTotal > 0">
  <div class="row g-4">
    <div class="col-sm-12">
      <div class="bg-light rounded h-100 p-4 table-responsive">
        <div class="col-sm-4" *ngIf="!view.isLoadingInyection">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar"
              (keyup)="updateFilter($event)"
            />
            <label for="Buscar">Buscar</label>
          </div>
        </div>
        <div class="row g-5">
            <div class="col-md-8 col-lg-9 col-sm-9" *ngIf="!view.isLoadingInyection">
                <ngx-datatable 
                  [rows]="itemsData"
                  [footerHeight]="50"
                  rowHeight="auto"
                  [headerHeight]="50"
                  [limit]="200"
                  class="bootstrap material"
                  [columnMode]="'force'"
                  [summaryRow]="true"
                  [summaryPosition]="'auto'"
                  [limit]="200"
                  [reorderable]="reorderable"
                  [scrollbarH]="true"
                >
                    <ngx-datatable-column name="Producto">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="col-md-11">
                                <div class="row">
                                    <div class="col-md-5">
                                        <a href="#">
                                            <img [src]="row.photo_url">
                                        </a>
                                    </div>
                                    <div class="col-md-7">
                                       {{row.name}}
                                       <h6 class="my-0">{{row.attributes.sku}}</h6>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Cantidad">
                        <ng-template let-row="row" name="QTY" ngx-datatable-cell-template>
                            <div><input step="any" type="number" id="newqty" min="0" name="newqty" (focus)="getOldValue($event)" [(ngModel)]="row.quantity" (change)="changeQty(row.attributes.sku, $event)" /></div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="partner_price" name="Precio">
                        <ng-template
                          let-row="row"
                          let-value="value"
                          ngx-datatable-cell-template
                        >
                            <div>
                                ${{value}}
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column prop="attributes.sku" name="Eliminar">
                         <ng-template
                          let-row="row"
                          let-value="value"
                          ngx-datatable-cell-template
                        >
                            <div>
                                <button type="button" class="btn btn-danger" (click)="editSku(value)"><i class="far fa-trash-alt"></i></button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
            <div class="col-md-4 col-lg-3 col-sm-3" *ngIf="!view.isLoadingInyection">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title text-primary">Detalle de la orden</h5>
                    <div class="card">
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngIf="isCoupon"><p class="card-text">Cupón: {{couponName}} <strong>${{discountAmount}}</strong></p></li>
                        <li class="list-group-item" *ngIf="shippingAmount > 0"><p class="card-text">Costo de envío <strong>${{shippingAmount}}</strong></p></li>
                        <li class="list-group-item"><p class="card-text">Total de la orden <strong>${{itemsModel.orderTotal}}</strong></p></li>
                      </ul>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-primary" [disabled]="isDisabled" (click)="processInyection()">Inyectar Pedido</button>
                      </div>
                  </div>
                </div>
            </div>
            <ng-template [ngIf]="view.isLoadingInyection" >
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status" >
                        <span class="sr-only" id="loading"></span>
                    </div>
                </div>
            </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>