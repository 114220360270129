import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';

@Component({
  selector: 'app-carrito-vtex',
  templateUrl: './carrito-vtex.component.html',
  styleUrls: ['./carrito-vtex.component.css']
})
export class CarritoVtexComponent implements OnInit {

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    //this.getStoreFilterPromo();
  }

}
