<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
        <div class="row">
            <h6 class="mb-4">{{view.title}}</h6>
        </div>

        <div class="row">
            <div class="col-sm-3">
                <div class="form-floating mb-3">
                    <select class="form-control" id="tipo" name="tipo" [(ngModel)]="tipo">
                        <option value=""></option>
                        <option value="SKU">Por SKU</option>
                        <option value="Id">Por ID</option>
                        <option value="Evento">Por ID Vtex</option>
                    </select>
                    <label for="tipo">Tipo</label>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="inputSend" [(ngModel)]="inputSend"
                        [disabled]="tipo == '' ? true : false">
                    <label for="inputSend">{{tipo}}</label>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-floating mb-3">
                    <select class="form-select" id="tienda" name="tienda" [(ngModel)]="Filtros.tienda" [disabled]="tipo == 'Id' || tipo == '' || tipo == 'Evento' ? true : false">
                        <option value="" selected>Todas</option>
                        <option *ngFor="let store of stores" value="{{store.code}}">{{store.name}}</option>
                    </select>
                    <label for="tipo">TIENDA</label>
                </div>
            </div>

        </div>
        <button class="btn btn-primary" (click)="listar()" [disabled]="view.isLoading" style="margin-right: 10px;">Buscar</button>
        <button class="btn btn-warning" (click)="clear()" [disabled]="view.isLoading">Limpiar</button>
    </div>
</div>
<div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12">
            <div class="bg-light rounded h-100 p-4 table-responsive">
                <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only" id="loading"></span>
                        </div>
                    </div>
                </ng-template>
                <ng-template #apiResponse>
                    <select [(ngModel)]="Filtros.page_size" (change)="listar()">
                        <option *ngFor="let size of selectSize" value="{{size}}">{{size}}</option>
                    </select>
                    <table class="table table-striped" style="width: 100%">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 7%;">Id</th>
                                <th scope="col" style="width: 10%;">Nombre</th>
                                <th scope="col" style="width: 10%;" *ngIf="tipo == 'SKU'">Tienda</th>
                                <th scope="col" style="width: 10%;">EventoLogix</th>
                                <!--<th scope="col" style="width: 10%;">urlModal</th>-->
                                <th scope="col" style="width: 10%;">Fecha Inicio</th>
                                <th scope="col" style="width: 10%;">Fecha Fin</th>
                                <th scope="col" style="width: 10%;">Estatus</th>
                                <th scope="col" style="width: 15%;">Skus Compra</th>
                                <th scope="col" style="width: 15%;">Skus Regalo</th>
                                <th scope="col" style="width: 15%;">Promocion</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template [ngIf]="resultados.length > 0" [ngIfElse]="notData">
                                <tr *ngFor="let Result of resultados">
                                    <td>{{Result.Id}}</td>
                                    <td>{{Result.Nombre}}</td>
                                    <td *ngIf="tipo == 'SKU'">{{Result.Tienda}}</td>
                                    <td>{{Result.EventoLogix}}</td>
                                    <!--<td>
                                        <div *ngIf="Result.urlModal == 'No se tiene modal'; else siModal">
                                            {{Result.urlModal}}
                                        </div>
                                        <ng-template #siModal>
                                            <a href="{{Result.urlModal}}" target="_blank">Link</a>
                                        </ng-template>
                                    </td>-->
                                    <td>{{Result.FechaInicio}}</td>
                                    <td>{{Result.FechaFin}}</td>
                                    <td>{{Result.Estatus}}</td>
                                    <td>{{Result.Skus_compra}}</td>
                                    <td>{{Result.skus_regalo}}</td>
                                    <td>{{Result.tipo_promo}}</td>
                                </tr>
                            </ng-template>
                            <ng-template #notData>
                                <tr>
                                    <td [colSpan]="tipo == 'SKU' || tipo == '' ? 11 : 11" scope="row"
                                        style="text-align: center;">Sin Resultados!!</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                    <ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true"
                        [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination>
                </ng-template>
            </div>
        </div>
    </div>
</div>