<div class="container-fluid pt-4 px-4">
  <div class="bg-light rounded-top p-4">
    <div class="row">
      <h6 class="mb-4">{{ view.title }}</h6>
    </div>
    <form [formGroup]="formUpDiscount" (ngSubmit)="validForm($event)">

    <div class="row">
     

      <div class="col-sm-4">
        <div class="form-floating mb-3">
          <input 
            type="date"
            name="startDate"
            placeholder="Fecha Inicial"
            class="form-control"
            required
            pattern="\d{4}-\d{2}-\d{2}"
            formControlName="startDate"
          />
          <label  for="startDate">Fecha Inicial</label>
          <div *ngIf="formUpDiscount.controls.startDate.errors && formUpDiscount.controls.startDate.touched">
            <small style="color: red;">Campo Obligatorio</small>
          </div>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-floating mb-3">
          <input 
            type="date"
            name="endDate"
            placeholder="Fecha Final"
            class="form-control"
            required
            pattern="\d{4}-\d{2}-\d{2}"
            formControlName="endDate"
          />
          <label  for="endDate">Fecha Final</label>
           <div *ngIf="formUpDiscount.controls.endDate.errors && formUpDiscount.controls.endDate.touched">
            <small style="color: red;">Campo Obligatorio</small>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-floating mb-3">
          <input 
            type="text"
            name="store"
            placeholder="Tienda"
            class="form-control"
            required
            pattern="\d{4}-\d{2}-\d{2}"
            [(ngModel)]="DiscountData.store"
            formControlName="store"
          />
          <label  for="store">Tienda</label>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-floating mb-3">
          <input 
            type="text"
            name="sku"
            placeholder="Sku"
            class="form-control"
            required
            pattern="\d{4}-\d{2}-\d{2}"
            [(ngModel)]="DiscountData.sku"
            formControlName="sku"
          />
          <label  for="sku">Sku</label>
        </div>
      </div>

    </div>
    
    
    <div class="form-floating">
      <div class="text-sm-end">
        <button *ngIf="showButton" type="submit" class="btn btn-dark">
          Solicitar Información
        </button>
        <ng-template [ngIf]="view.isLoading" >
          <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status" >
                <span class="sr-only" id="loading"></span>
              </div>
            </div>
      </ng-template>
      </div>
    </div>
   
  </form>
  </div>
</div>

<div class="container-fluid pt-4 px-4">
  <div class="row g-4">
    <div class="col-sm-12">
      <div class="bg-light rounded h-100 p-4 table-responsive">
       
        <div *ngIf="showFind" class="col-sm-4">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar"
              (keyup)="updateFilter($event)"
            />
            <label for="Buscar">Buscar</label>
          </div>
        </div>

        <div  *ngIf="showFind" class="form-floating">
          <div class="text-sm-end">
            <button class="btn btn-primary" (click)="export()">Exportar Pedidos Cat!</button>
            <ng-template [ngIf]="view.isLoading" >
              <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status" >
                    <span class="sr-only" id="loading"></span>
                  </div>
                </div>
          </ng-template>
          </div>
        </div>


        <ngx-datatable 
          [rows]="filteredRows"
          [columns]="columns"
          [footerHeight]="50"
          rowHeight="auto"
          [headerHeight]="50"
          [limit]="10"
          class="bootstrap"
          [columnMode]="'force'"
          [summaryRow]="true"
          [summaryPosition]="'auto'"
          [limit]="10"
          [reorderable]="reorderable"
          [scrollbarH]="true"
        >
          
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
