
<div class="container-fluid pt-4 px-4">
  <div class="bg-light rounded-top p-4">
    <div class="card card-primary border border-secondary " >

     
          <div class="card-header p-3 mb-2 bg-primary text-white">
            <i class="fa fa-briefcase me-2"></i><span class="mb-4">{{ view.title }}</span>
          </div>

    <div class="card-body">

    <form [formGroup]="formUpDiscount" (ngSubmit)="validForm($event)">
      <div class="row">

        <div class="col-sm-2">
          <div class="form-floating mb-3">
            <input
              type="date"
              name="startDate"
              placeholder="Fecha Inicial"
              class="form-control"
              required
              pattern="\d{4}-\d{2}-\d{2}"
              formControlName="startDate"
            />
            <label for="startDate">Fecha Inicial</label>
            <div
              *ngIf="
                formUpDiscount.controls.startDate.errors &&
                formUpDiscount.controls.startDate.touched
              "
            >
              <small style="color: red">Campo Obligatorio</small>
            </div>
          </div>
        </div>

        <div class="col-sm-2">
          <div class="form-floating mb-3">
            <input 
            class="form-control"
            type="time" 
            name="startHour" 
            [(ngModel)]="DiscountData.startHour"
            placeholder="HH:mm:ss" 
            min="00:00:00" 
            max="23:59:59" 
            formControlName="startHour"
             />
            <label for="startHour">Hora Inicial</label>

          </div>
          
        </div>

        <div class="col-sm-2">
          <div class="form-floating mb-3">
            <input
              type="date"
              name="endDate"
              placeholder="Fecha Final"
              class="form-control"
              required
              pattern="\d{4}-\d{2}-\d{2}"
              formControlName="endDate"
            />
            <label for="endDate">Fecha Final</label>
            <div
              *ngIf="
                formUpDiscount.controls.endDate.errors &&
                formUpDiscount.controls.endDate.touched
              "
            >
              <small style="color: red">Campo Obligatorio</small>
            </div>
          </div>
        </div>

        <div class="col-sm-2">
          <div class="form-floating mb-3">
            <input 
            class="form-control"
            type="time" 
            id="endHour" 
            name="endHour" 
            [(ngModel)]="DiscountData.endHour"
            placeholder="HH:mm:ss" 
            min="00:00:00" 
            max="23:59:59" 
             
            formControlName="endHour"
            />
            <label for="endHour">Hora Final</label>
          </div>
         
        </div>


        <div class="col-sm-2">
          <div class="form-floating mb-3">
            <select   
            id="store" 
            name="store"
            placeholder="Tienda"
            class="form-control"
            
           
            formControlName="store"  
            [(ngModel)]="DiscountData.store">
              <option *ngFor="let opcion of storeData" [value]="opcion.uc">{{ opcion.SUC_NOMBRE}}</option>
              
            </select>

            <label for="store">Tienda</label>
          </div>
        </div>

       


        <div class="col-sm-2">
          <div class="form-floating mb-3">
            
            
            <select   
            id="payment_code" 
            name="payment_code"
            placeholder="Metodo de Pago"
            class="form-control"
            
          
            formControlName="payment_code"  
            [(ngModel)]="DiscountData.payment_code">
            
              <option *ngFor="let opcion of paymentData" [value]="opcion.code">{{ opcion.payment}}</option>
            </select>
            <label for="Metodo de pago">Metodo de Pago</label>
          </div>
        </div>
      </div>

      <div class="form-floating">
        <div class="text-sm-end">
          <button *ngIf="showButton" type="submit" class="btn btn-dark">
            <i class='fas fa-search me-2'></i> Buscar
          </button>
          <ng-template [ngIf]="view.isLoading">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only" id="loading"></span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </form>

  </div>
  
  </div>
</div>
</div>

<div  *ngIf="showFind" class="container-fluid pt-4 px-4">
  <div class="row g-4">
    <div class="col-sm-12">
      <div class="bg-light rounded h-100 p-4 table-responsive">
        
        <div *ngIf="showFind" class="col-sm-4">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar"
              (keyup)="updateFilter($event)"
            />
            <label for="Buscar">Buscar</label>
          </div>
          
        </div>
        <div *ngIf="showFind" class="form-floating">
          <div class="text-sm-end">
            <button class="btn btn-outline-secondary" (click)="export()">
              <i class='fas fa-file-export me-2'></i> Exportar
            </button>
        
          </div>
          
        </div>
       

        <div>        <ngx-datatable
           #table
          [rows]="filteredRows"
          [columns]="columns"
          [footerHeight]="50"
          [headerHeight]="50"
          [rowHeight]="80"
          [limit]="5"
          class="material"
          [columnMode]="'force'"
          [summaryRow]="true"
          [summaryPosition]="'auto'"
          [reorderable]="reorderable"
          
          [scrollbarH]="true"

          
       

        >
          <ngx-datatable-column name="ORDER REFERENCE">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.order_reference }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="CUSTOMER REFERENCE">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.customer_reference }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="CREATE DATE"> 
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.create_date }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="STORE ID"> 
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.store_id }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="STATUS"> 
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ row.status }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="PAYMENTS">
            <ng-template  let-row="row"  ngx-datatable-cell-template>

              <div style="padding-left:5%;">
                <div><strong>TID</strong></div>

                <div *ngFor="let payment of row.payments">
                  <div class="row">
                    {{ payment.tid }}
                  </div>

                </div>
              </div>

             
           
            </ng-template>

            

          </ngx-datatable-column>
        </ngx-datatable>
      </div>

      </div>
    </div>
  </div>
</div>
