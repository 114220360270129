import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AppService } from 'src/app/app.service';
interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  sku: string | null,
  store: string | null
}

@Component({
  selector: 'app-reduce',
  templateUrl: './reduce.component.html',
  styleUrls: ['./reduce.component.css']
})
export class ReduceComponent implements OnInit {

  view: view = {
    title: 'Rebajados',
    isLoading: false,
    _url: `/oms/discounts`
  }
  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  stores: any[] = [];
  total: number = 0;
  loadingIndicator              = true;
  reorderable                   = true;
  options                       = {}
  dat           : any           = [];
  columns       : any           = {};
  filteredRows  : any           = []; // inicialmente son los mismos que rows
  skus          : any           = []; 
  storeData     : any           = [];
 
  selectSize = [5, 10, 20, 50, 100]

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder,
    private appService:AppService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    //this.getStoreFilter();
    this.storeData = this.appService.getStoreData();
    this.columns = [
      { name: 'SKU', prop: 'sku' },
      { name: 'PRODUCTO', prop:'name'},
      { name: 'PRECIO', prop: 'precio' },
      { name: 'REBAJA', prop: 'rebaja' },
      { name: 'TIENDA', prop: 'tienda' },
      { name: 'REBAJADO', prop: 'rebajado' },
      { name: 'INICIO', prop: 'fecha_inicio' },
      { name: 'FIN', prop: 'fecha_fin' },
     
    ];

  }

  listar() {
    this.resultados = [];
    this.dat        = [];
    this.view.isLoading = true;
    this.showButton     = false;
    this.showFind       = false;
    this.API._get(this.view._url, this.Filtros).subscribe((res: any) => {
  
      this.view.isLoading = false;
      this.showButton     = true;
      if(res.result == 'ok') {
        if (  res.data != ""  ) {
          this.appService.getShowToastFormat("Información exitosa.","success");
          this.dat =  res.data ;
          this.filteredRows = this.dat;
          this.showFind = true;
        }else{
          this.showFind = false;
          //console.log("dat  " + JSON.stringify( res.data));
          this.appService.getShowModalInfoFormat("¡AVISO!","No hay información relacionada con los datos proporcionados.","info");
          //this.Helper.show('No existe informacion en base a la proporcionado '  , { classname: `bg-warning text-light`, delay: 1500 });
        }
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          //this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
          this.appService.getShowModalInfoFormat("ERROR RESPONSE","Error en la respuesta del servicio:  "+ res.e,"error");
        }
      }
    }, (e: any) => {
      this.appService.getShowModalInfoFormat("ERROR RESPONSE","Error en la llamada del servicio:  "+ e,"error");
      console.warn('search Discount -> ', e);
    });
  }


  formUpDiscount = this.formBuilder.group({
    store:[''],
    sku:['']
  });

  validForm(event: Event) {
    event.preventDefault();
    //if(this.formUpDiscount.valid) {
      
     // const startDate = new Date(this.formUpDiscount.value.startDate);
      //const endDate = new Date(this.formUpDiscount.value.endDate);

      /*if (startDate > endDate) {
        this.Helper.show('La fecha de inicio no puede ser posterior a la fecha de fin. ', { classname: `bg-danger text-light`, delay: 3500 });
      } else {*/
      
        this.listar();
      //}
    /*} else {
      this.formUpDiscount.markAllAsTouched();
    }*/
  }


  getStoreFilter() {
    
    this.API._get("/stores", []).subscribe((res: any) => {
      console.log(res)
      
      if(res.result == 'ok') {
        this.stores = res.data;
        /*this.resultados = res.data.data;
        this.total = res.data.total * 12;*/
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('search Reduce -> ', e);
    });
  }

  clear(): void {
    this.Filtros = this.emptyFilters();
   
  }

  emptyFilters(): Filtros {
    return {
      sku: null,
      store: null
    };
  }

  updateFilter(event : any) {
    const val = event.target.value.toLowerCase();
  
    // filtrar los datos según el término de búsqueda
    const temp = this.dat.filter(function(d: any) {
      return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
    });
  
    // actualizar la variable que contiene los datos filtrados
    this.filteredRows = temp;
  }

  public showButton : boolean = true;
  public showFind : boolean = false;
}
