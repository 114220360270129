import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GuardianService } from './services/guardian.service';
import { SysguardianService } from './services/sysguardian.service';

import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { ReduceComponent } from './pages/magento/reduce/reduce.component';
import { OrderComponent } from './pages/magento/order/order.component';
import { StoreCodeComponent } from './pages/magento/store-code/store-code.component';
import { RecurringOrdersComponent } from './pages/recurrentes/recurring-orders/recurring-orders.component';
import { PromisedDatesComponent } from './pages/recurrentes/promised-dates/promised-dates.component';
import { PrDetailComponent } from './pages/recurrentes/pr-detail/pr-detail.component';
import { PrConfigComponent } from './pages/recurrentes/pr-config/pr-config.component';
import { PrLogsComponent } from './pages/recurrentes/pr-logs/pr-logs.component';
import { ProfilesComponent } from './pages/sistema/profiles/profiles.component';
import { UsersComponent } from './pages/sistema/users/users.component';
import { ProfileComponent } from './pages/sistema/profile/profile.component';
import { AwsIpComponent } from './pages/aws-ip/aws-ip.component';
import { PromotionsComponent } from './pages/magento/promotions/promotions.component';
import { UpdatePasswordComponent } from './pages/sistema/update-password/update-password.component';
import { ModulesComponent } from './pages/sistema/modules/modules.component';
import { ShoppingCartComponent } from './pages/magento/shopping-cart/shopping-cart.component';
import { MyprofileComponent } from './pages/myprofile/myprofile.component';
import { LastOrdersComponent } from './pages/magento/last-orders/last-orders.component';
import { AltaRebajadosComponent } from './pages/magento/alta-rebajados/alta-rebajados.component';
import { UserComponent } from './pages/sistema/user/user.component';
import { RushOrdersComponent } from './pages/magento/rush-orders/rush-orders.component';
import { PushRordersComponent } from './pages/events/push-rorders/push-rorders.component';
import { PushInyeccionComponent } from './pages/events/push-inyeccion/push-inyeccion.component';
import { StockComponent } from './pages/events/stock/stock.component';
import { ConciliacionComponent } from './pages/conciliacion/conciliacion.component';
import { DataMoventsComponent } from './pages/events/data-movents/data-movents.component';
import { CarritoVtexComponent } from './pages/vtex/carrito-vtex/carrito-vtex.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ GuardianService ]
  },
  { path: 'login', component: LoginComponent },
  { 
    path: 'reduce',
    component: ReduceComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  { 
    path: 'promotions',
    component: PromotionsComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'change-store-order',
    component: OrderComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'store-by-code',
    component: StoreCodeComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'recurring-orders',
    component: RecurringOrdersComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'promised-dates',
    component: PromisedDatesComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'pr-detail/:id',
    component: PrDetailComponent,
    canActivate: [ GuardianService ]
  },
  {
    path: 'pr-config',
    component: PrConfigComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'pr-logs',
    component: PrLogsComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'profiles',
    component: ProfilesComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'profile/:id',
    component: ProfileComponent,
    canActivate: [ GuardianService ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ GuardianService ]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'aws-ip',
    component: AwsIpComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'update-password',
    component: UpdatePasswordComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'modules',
    component: ModulesComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'shopping-cart',
    component: ShoppingCartComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'myprofile',
    component: MyprofileComponent,
    canActivate: [ GuardianService ]
  },
  {
    path: 'last-orders',
    component: LastOrdersComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [GuardianService]
  },
  {
    path: 'user/:id',
    component: UserComponent,
    canActivate: [GuardianService]
  },
  {
    path: 'reb-price',
    component: AltaRebajadosComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'rushorders',
    component: RushOrdersComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'pushorders',
    component: PushRordersComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'pushinyeccion',
    component: PushInyeccionComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'stock',
    component: StockComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'conciliacion',
    component: ConciliacionComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'dmovents',
    component: DataMoventsComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },
  {
    path: 'ccarrito',
    component: CarritoVtexComponent,
    canActivate: [ GuardianService, SysguardianService ]
  },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
