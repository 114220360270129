<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
      <div class="row">
        <h6 class="mb-4">{{ view.title }}</h6>
      </div>
      <form [formGroup]="formUpStock" (ngSubmit)="validForm($event)">
  
      <div class="row">
    
        <div class="col-sm-2">
          <div class="form-floating mb-3">
            <input type="text" name="sku" placeholder="Sku(Heb)" class="form-control" formControlName="sku"
              required [(ngModel)]="Filtros.sku" />
            <label for="sku">Sku (Heb)</label>
          </div>
          <div *ngIf="formUpStock.controls.sku.errors && formUpStock.controls.sku.touched">
            <small style="color: red;">Campo Obligatorio</small>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-floating mb-3">
              <select class="form-control" name="updatestore" formControlName="updatestore" required="required"
              [(ngModel)]="Filtros.store">
                <option *ngFor="let store of upstores" value="{{store.value}}">{{store.name}}</option>
              </select>
              <label for="updatestore">Tienda</label>
              <div *ngIf="formUpStock.controls.updatestore.errors && formUpStock.controls.updatestore.touched">
                <small style="color: red;">Campo Obligatorio</small>
              </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-floating mb-3">
              <select class="form-control" name="updatetype" formControlName="updatetype" required="required"
              [(ngModel)]="Filtros.typeup">
                <option *ngFor="let uptype of uptypes" value="{{uptype.value}}">{{uptype.name}}</option>
              </select>
              <label for="updatetype">Dato a Actualizar</label>
              <div *ngIf="formUpStock.controls.updatetype.errors && formUpStock.controls.updatetype.touched">
                <small style="color: red;">Campo Obligatorio</small>
              </div>
          </div>
        </div>
      </div>
      
      <div class="form-floating">
        <div class="text-sm-end">
          <button *ngIf="showButton" type="submit" class="btn btn-dark">
            Solicitar Información
          </button>
          <ng-template [ngIf]="view.isLoading" >
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status" >
                  <span class="sr-only" id="loading"></span>
                </div>
              </div>
          </ng-template>
        </div>
      </div>
     
    </form>
    </div>
  </div>

  <!-- tabla informacino de Inventarios -->
  <ng-container *ngIf="showTableStock">
    <div class="container-fluid pt-4 px-4">
      <div class="row g-4">
          <div class="col-sm-12">
              <div class="bg-light rounded h-100 p-4 table-responsive">
                  <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                      <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status" >
                            <span class="sr-only" id="loading"></span>
                          </div>
                        </div>
                  </ng-template>
                  <ng-template #apiResponse>
                    <form [formGroup]="formUpPrice" (ngSubmit)="validFormPrice($event)">

                      <ngx-datatable
                        [rows]="resultStockRows"
                        [footerHeight]="50"
                        rowHeight="auto"
                        [headerHeight]="50"
                        class="bootstrap material"
                        [columnMode]="'force'"
                        [summaryRow]="true"
                        [summaryPosition]="'auto'"
                        [limit]="100"
                        [scrollbarH]="true"
                      >
                      <ngx-datatable-column name="WAREHOUSE ID" prop="warehouseId"></ngx-datatable-column>
                      <ngx-datatable-column name="WAREHOUSE NAME" prop="warehouseName"></ngx-datatable-column>
                      <ngx-datatable-column name="TOTAL QUANTITY" prop="totalQuantity">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{row?.totalQuantity}}</span>
                            <input type="text" name="rusqty" class="form-control" 
                              id="{{row?.warehouseId}}_rusqty"
                              required value="{{row?.totalQuantity}}"
                              [style.display]="'none'" />
                          </ng-template>
                      </ngx-datatable-column>
                      <!-- <ngx-datatable-column name="HAS UNLIMIT QUANTITY" prop="hasUnlimitedQuantity">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{row?.hasUnlimitedQuantity}}</span>
                            <input type="text" name="rusunlimitqty" class="form-control" 
                              id="{{row?.warehouseId}}_rusunlimitqty"
                              required value="{{row?.hasUnlimitedQuantity}}" 
                              [style.display]="'none'" />
                          </ng-template>
                      </ngx-datatable-column> -->
                      <ngx-datatable-column name="ACTION" prop="">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <ng-container *ngIf="showBtnEditStock">
                            <button type="button" class="btn btn-primary" 
                            id="{{row?.warehouseId}}_btneditstock"
                            value="{{row?.warehouseId}}"
                            (click)="enableUpdateStock($event)">Editar</button>
                          </ng-container>
                        
                          <button type="button" class="btn btn-success" 
                          id="{{row?.warehouseId}}_btnupdatestock"
                          value="{{row?.warehouseId}}"
                          [style.display]="'none'"
                          (click)="updateStockItem($event)">Actualizar</button>

                        </ng-template>
                      </ngx-datatable-column>

                      </ngx-datatable>

                      <!-- 
                      <table class="table table-striped">
                          <thead>
                              <tr>
                                  <th scope="col">Sku</th>
                                  <th scope="col">Warehouse Id</th>
                                  <th scope="col">Warehouse Name</th>
                                  <th scope="col">Total Quantity</th>
                                  < ! - - <th scope="col">Reserved Quantity</th> - - >
                                  <th scope="col">HasUnlimited Quantity</th>
                                  <th scope="col">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <ng-template [ngIf]="resultadosStock.length > 0" [ngIfElse]="notData">
                                  <tr *ngFor="let ResultStock of resultadosStock">
                                      <td>{{Filtros.sku}} </td>
                                      <td>{{ResultStock.warehouseId}}</td>
                                      <td>{{ResultStock.warehouseName}}</td>
                                      <td>{{ResultStock.totalQuantity}}
                                        <input type="text" name="rusqty" class="form-control" 
                                        id="{{ResultStock.warehouseId}}_rusqty"
                                        required value="{{ResultStock.totalQuantity}}"
                                        [style.display]="'none'" />
                                      </td>
                                      < ! - - <td>{{ResultStock.reservedQuantity}}
                                        <input type="text" name="rusreserveqty" class="form-control" 
                                        id="{{ResultStock.warehouseId}}_rusreserveqty"
                                        required value="{{ResultStock.reservedQuantity}}" 
                                        [style.display]="'none'" />
                                      </td>  - - >
                                      <td>{{ResultStock.hasUnlimitedQuantity}}
                                        <input type="text" name="rusunlimitqty" class="form-control" 
                                        id="{{ResultStock.warehouseId}}_rusunlimitqty"
                                        required value="{{ResultStock.hasUnlimitedQuantity}}" 
                                        [style.display]="'none'" />
                                      </td>
                                      <td>
                                        <ng-container *ngIf="showBtnEditStock">
                                          <button type="button" class="btn btn-primary" 
                                          id="{{ResultStock.warehouseId}}_btneditstock"
                                          value="{{ResultStock.warehouseId}}"
                                          (click)="enableUpdateStock($event)">Editar</button>
                                        </ng-container>
  
                                        <button type="button" class="btn btn-success" 
                                        id="{{ResultStock.warehouseId}}_btnupdatestock"
                                        value="{{ResultStock.warehouseId}}"
                                        [style.display]="'none'"
                                        (click)="updateStockItem($event)">Actualizar</button>
                                        
                                      </td>
                                  </tr>
                              </ng-template>
                              <ng-template #notData>
                                  <tr>
                                      <td colspan="7" scope="row" style="text-align: center;">No se encontraron Resultados!!</td>
                                  </tr>
                              </ng-template>
                            
                          </tbody>
                      </table> -->
                    </form>
                      <!-- <ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true" [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination> -->
                  </ng-template>
              </div>
          </div>
      </div>
    </div>
    </ng-container>

  <!-- tabla informacino de precios BASE-->
  <ng-container *ngIf="showTablePrice">
  <div class="container-fluid pt-4 px-4">
    <div class="row g-4">
        <div class="col-sm-12">
            <div class="bg-light rounded h-100 p-4 table-responsive">
                <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status" >
                          <span class="sr-only" id="loading"></span>
                        </div>
                      </div>
                </ng-template>
                <ng-template #apiResponse>
                  <form [formGroup]="formUpPrice" (ngSubmit)="validFormPrice($event)">

                    <ngx-datatable
                        [rows]="resultPriceRows"
                        [footerHeight]="50"
                        rowHeight="auto"
                        [headerHeight]="50"
                        class="bootstrap material"
                        [columnMode]="'force'"
                        [summaryRow]="true"
                        [summaryPosition]="'auto'"
                        [limit]="100"
                        [scrollbarH]="true"
                      >

                      <ngx-datatable-column name="SKU (Vtex)" prop="itemId">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{row?.itemId}}</span>
                            <ng-container *ngIf="showFieldEdit">
                              <input type="hidden" name="rupsku" class="form-control" 
                                formControlName="uppsku"
                                required [(ngModel)]="FiltrosUpdate.sku"
                                value="{{row?.itemId}}" />
                            </ng-container>
                          </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-column name="BASE PRICE" prop="basePrice">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{row?.basePrice}}</span>
                            <ng-container *ngIf="showFieldEdit">
                              <input type="text" name="rupbaseprice" class="form-control" 
                                formControlName="uppbaseprice"
                                required [(ngModel)]="FiltrosUpdate.baseprice"
                                value="{{row?.baseprice}}" />
                            </ng-container>
                          </ng-template>
                      </ngx-datatable-column>

                      <!-- <ngx-datatable-column name="MARKUP" prop="markup">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <span>{{row?.markup}}</span>
                          <ng-container *ngIf="showFieldEdit">
                            <input type="text" name="rupmarkup" class="form-control" 
                            formControlName="uppmarkup"
                            required [(ngModel)]="FiltrosUpdate.markup"
                            value="{{row?.markup}}" />
                          </ng-container>
                        </ng-template>
                      </ngx-datatable-column> -->

                      <!-- <ngx-datatable-column name="COST PRICE" prop="costPrice"></ngx-datatable-column>
                      <ngx-datatable-column name="LIST PRICE" prop="listPrice">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{row?.listPrice}}</span>
                            <ng-container *ngIf="showFieldEdit">
                                <input type="text" name="ruplistprice" class="form-control" 
                                  formControlName="upplistprice"
                                  required  [(ngModel)]="FiltrosUpdate.listprice"
                                  value="{{row?.listPrice}}" />
                            </ng-container>
                          </ng-template>
                      </ngx-datatable-column> -->
                      <ngx-datatable-column name="ACTION" prop="">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <ng-container *ngIf="showBtnEdit">
                            <button type="button" class="btn btn-primary" id="btnedit" 
                            (click)="enableUpdate()">Editar</button>
                          </ng-container>
                        
                          <ng-container *ngIf="showFieldEdit">
                            <button class="btn btn-success" id="btnupdate" type="submit">Actualizar</button>
                          </ng-container>
                          
                        </ng-template>
                      </ngx-datatable-column>

                    </ngx-datatable>
                    
                  </form>
                    <!-- <ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true" [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination> -->
                </ng-template>
            </div>
        </div>
    </div>
  </div>
  </ng-container>

  <!-- tabla informacino de precios LIST PRICE -->
  <ng-container *ngIf="showTablePriceList">
    <div class="container-fluid pt-4 px-4">
      <div class="row g-4">
          <div class="col-sm-12">
              <div class="bg-light rounded h-100 p-4 table-responsive">
                  <ng-template [ngIf]="view.isLoading" [ngIfElse]="apiResponse">
                      <div class="d-flex justify-content-center">
                          <div class="spinner-border" role="status" >
                            <span class="sr-only" id="loading"></span>
                          </div>
                        </div>
                  </ng-template>
                  <ng-template #apiResponse>
                    <form [formGroup]="formUpPriceList" (ngSubmit)="validFormPriceList($event)">
  
                      <ngx-datatable
                          [rows]="resultPriceRows"
                          [footerHeight]="50"
                          rowHeight="auto"
                          [headerHeight]="50"
                          class="bootstrap material"
                          [columnMode]="'force'"
                          [summaryRow]="true"
                          [summaryPosition]="'auto'"
                          [limit]="100"
                          [scrollbarH]="true"
                        >
  
                        <ngx-datatable-column name="SKU(Vtex)" prop="itemId">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                              <span>{{row?.itemId}}</span>
                              <ng-container *ngIf="showFieldEditPl">
                                <input type="hidden" name="rupsku" class="form-control" 
                                  formControlName="uppsku"
                                  required [(ngModel)]="FiltrosUpdate.sku"
                                  value="{{row?.itemId}}" />
                              </ng-container>
                            </ng-template>
                        </ngx-datatable-column>
  
                        <!-- <ngx-datatable-column name="BASE PRICE" prop="basePrice">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                              <span>{{row?.basePrice}}</span>
                              <ng-container *ngIf="showFieldEdit">
                                <input type="text" name="rupbaseprice" class="form-control" 
                                  formControlName="uppbaseprice"
                                  required [(ngModel)]="FiltrosUpdate.baseprice"
                                  value="{{row?.baseprice}}" />
                              </ng-container>
                            </ng-template>
                        </ngx-datatable-column> -->
  
                        <!-- <ngx-datatable-column name="MARKUP" prop="markup">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{row?.markup}}</span>
                            <ng-container *ngIf="showFieldEdit">
                              <input type="text" name="rupmarkup" class="form-control" 
                              formControlName="uppmarkup"
                              required [(ngModel)]="FiltrosUpdate.markup"
                              value="{{row?.markup}}" />
                            </ng-container>
                          </ng-template>
                        </ngx-datatable-column> -->
  
                        <!-- <ngx-datatable-column name="COST PRICE" prop="costPrice"></ngx-datatable-column> -->
                        <ngx-datatable-column name="LIST PRICE" prop="listPrice">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                              <span>{{row?.listPrice}}</span>
                              <ng-container *ngIf="showFieldEditPl">
                                  <input type="text" name="ruplistprice" class="form-control" 
                                    formControlName="upplistprice"
                                    required  [(ngModel)]="FiltrosUpdate.listprice"
                                    value="{{row?.listPrice}}" />
                              </ng-container>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="ACTION" prop="">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-container *ngIf="showBtnEditPl">
                              <button type="button" class="btn btn-primary" id="btnedit" 
                              (click)="enableUpdatePL()">Editar</button>
                            </ng-container>
                          
                            <ng-container *ngIf="showFieldEditPl">
                              <button class="btn btn-success" id="btnupdate" type="submit">Actualizar</button>
                            </ng-container>
                            
                          </ng-template>
                        </ngx-datatable-column>
  
                      </ngx-datatable>
                      
                    </form>
                      <!-- <ngb-pagination [collectionSize]="total" [pageSize]="12" [maxSize]="6" [rotate]="true" [ellipses]="false" [(page)]="Filtros.page" (pageChange)="pagination($event)"></ngb-pagination> -->
                  </ng-template>
              </div>
          </div>
      </div>
    </div>
    </ng-container>
  