import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx';


type AOA = any[][];
interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface DiscountData {
  startDate: string | null,
  endDate: string | null,
  store:string | null,
  sku:string | null
}

@Component({
  selector: 'app-rush-orders',
  templateUrl: './rush-orders.component.html',
  styleUrls: ['./rush-orders.component.css']
})
export class RushOrdersComponent implements OnInit{

  loadingIndicator              = true;
  reorderable                   = true;
  options                       = {}
  dat           : any           = [];
  columns       : any           = {};
  filteredRows  : any           = []; // inicialmente son los mismos que rows
  skus          : any           = []; 
  resultados    : any[]         = [];
  DiscountData  : DiscountData  = this.emptyFilters();
  arrayData: any = [];
  arrayHeader: any = [];

  view: view = {
    title: 'PEDIDOS CAT',
    isLoading: false,
    _url: `/oms/rushorders`
  }

  


  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.showFind = false;
    //this.getStoreFilterPromo();
    this.columns = [
      { name: 'NUM.PEDIDO', prop: 'ordr_num' },
      { name: 'SKU', prop: 'sku' },
      { name: 'PRODUCTO', prop:'product_name'},
      { name: 'ORDENADO', prop: 'qty_ordered' },
      { name: 'STATUS_CAT', prop: 'status' },
      { name: 'TIENDA', prop: 'store' },
      { name: 'FECHA ORDENADO', prop: 'request_date' },
      { name: 'FECHA_CREA', prop: 'create_date' },
      { name: 'RESPONSE', prop: 'xml_response' },
      { name: 'STATUS RUSH', prop: 'StatusCreateRush' },
      { name: 'RECIBIDO', prop: 'receipt_store_date' },
      { name: 'TARIMA', prop: 'final_pallet' },
      { name: 'DAÑADO', prop: 'damaged' },
      { name: 'invc_id', prop: 'invc_id' },
      { name: 'ENVIADO', prop: 'sent' },
      { name: 'NO ENVIADO', prop: 'not_sent' },
      { name: 'ordr_exe', prop: 'ordr_exe' },
      { name: 'ordr_type', prop: 'ordr_type' },
      { name: 'ULTIMA ACTUALIZACION', prop: 'last_update' },
      { name: 'shipping_num', prop: 'shipping_num' },
      { name: 'dispatch_date', prop: 'dispatch_date' },
      { name: 'processing_date', prop: 'processing_date' },
      { name: 'shipping_status', prop: 'shipping_status' },
      { name: 'ordr_num_cat_ext', prop: 'ordr_num_cat_ext' }
    ];
  }

  emptyFilters(): DiscountData {
    return {
      startDate: null,
      endDate: null,
      store:null,
      sku:null
    };
  }

  formUpDiscount = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    store:[''],
    sku:['']
  });

  validForm(event: Event) {
    event.preventDefault();
    //if(this.formUpDiscount.valid) {
      
      const startDate = new Date(this.formUpDiscount.value.startDate);
      const endDate = new Date(this.formUpDiscount.value.endDate);

      if (startDate > endDate) {
        this.Helper.show('La fecha de inicio no puede ser posterior a la fecha de fin. ', { classname: `bg-danger text-light`, delay: 3500 });
      } else {
      
      //const fechaFormateada  =   this.formatDates( startDate);
      this.DiscountData.startDate = this.formatDates( startDate);
      this.DiscountData.endDate = this.formatDates( endDate);
      console.log( this.DiscountData.startDate  );
        this.agregarDiscount();
      }
    /*} else {
      this.formUpDiscount.markAllAsTouched();
    }*/
  }

  formatDates( date: any) : string {
    let fechaFormateada  =   formatDate( date, 'dd-MM-yy','en_US','+0430');
    return fechaFormateada;
  }
  
  agregarDiscount() : void {

    this.resultados = [];
    this.dat        = [];
    this.view.isLoading = true;
    this.showButton     = false;
    this.API._get(this.view._url, this.DiscountData).subscribe((res: any) => {
  
      this.view.isLoading = false;
      this.showButton     = true;
      if(res.result == 'ok') {
        this.dat =  res.data ;
        this.filteredRows = this.dat;
        this.showFind = true;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('search Discount -> ', e);
    });

  }

  updateFilter(event : any) {
    const val = event.target.value.toLowerCase();
  
    // filtrar los datos según el término de búsqueda
    const temp = this.dat.filter(function(d: any) {
      return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
    });
  
    // actualizar la variable que contiene los datos filtrados
    this.filteredRows = temp;
  }

    // EXCEL READ 
    dataR: AOA = [[1, 2], [3, 4]];
  
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    fileName: string = 'PedidosCAT.xlsx';

    export(): void {
      this.view.isLoading = true;
      /* generate worksheet */



      this.arrayHeader[0]  =  [
                              'NUM.PEDIDO',                     
                              'SKU',                                        
                              'PRODUCTO',                           
                              'ORDENADO',                          
                              'STATUS_CAT',                   
                              'TIENDA',                      
                              'FECHA ORDENADO',                     
                              'FECHA_CREACION',                        
                              'RECIBIDO',                                 
                              'TARIMA',                            
                              'DAÑADO',                       
                              'INVOICE ID',                      
                              'NO ENVIADO',                    
                              'ORDER EXE',                      
                              'ORDER TYPE',                      
                              'ULTIMA ACTUALIZACION',             
                              'SHIPING NUM',                      
                              'DISPATCH DATE',                      
                              'PROCESSING DATE',                      
                              'SHIPPING STATUS',                      
                              'ORDER NUM CAT EXT'
                        ];                 
      
      this.arrayData = this.dat.map((objeto: { 
                                                ordr_num : any;
                                                sku : any;               
                                                product_name : any;
                                                qty_ordered : any;
                                                status : any;
                                                store : any;
                                                request_date : any;
                                                create_date : any;
                                                receipt_store_date : any;
                                                final_pallet: any;
                                                damaged: any;
                                                invc_id: any;
                                                not_sent: any;
                                                ordr_exe: any;
                                                ordr_type: any;
                                                last_update: any;
                                                shipping_num: any;
                                                dispatch_date: any;
                                                processing_date: any;
                                                shipping_status: any;
                                                ordr_num_cat_ext: any;
                                            }) => [
                                                    objeto.ordr_num, 
                                                    objeto.sku, 
                                                    objeto.product_name,
                                                    objeto.qty_ordered,
                                                    objeto.status,
                                                    objeto.store,
                                                    objeto.request_date,
                                                    objeto.create_date,
                                                    objeto.receipt_store_date,
                                                    objeto.final_pallet,
                                                    objeto.damaged,
                                                    objeto.invc_id,
                                                    objeto.not_sent,
                                                    objeto.ordr_exe,
                                                    objeto.ordr_type,
                                                    objeto.last_update,
                                                    objeto.shipping_num,
                                                    objeto.dispatch_date,
                                                    objeto.processing_date,
                                                    objeto.shipping_status,
                                                    objeto.ordr_num_cat_ext
                                                  ]);
      //this.arrayHeader.push(this.arrayData);

      const newArray =  this.arrayHeader.concat(this.arrayData);


      //console.log((newArray));
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(newArray);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.view.isLoading = false;
    }


  public showButton : boolean = true;
  public showFind : boolean = false;
}
