import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';

interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface Filtros {
  sku: string | null,
  id: string | null,
  description: string | null,
  page: number,
  page_size: number | null,
  tienda: string | null
}

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {

  view: view = {
    title: 'Promociones',
    isLoading: false,
    _url: `promotions`
  }
  Filtros: Filtros = this.emptyFilters();
  resultados: any[] = [];
  stores: any[] = [];
  total: number = 0;
  tipo: any = '';
  inputSend: any = '';
  selectSize = [5, 10, 20, 50, 100]

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.getStoreFilterPromo();
  }

  listar() {
    this.resultados = [];
    this.total = 0;
    this.InputSerach();
    this.view.isLoading = true;
    this.API._get(`promotions`, this.Filtros).subscribe((res: any) => {
      console.log(res)
      this.view.isLoading = false;
      if(res.result == 'ok') {
        this.resultados = res.data.data;
        this.total = res.data.total * 12;
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('search Reduce -> ', e);
    });
  }
  
  getStoreFilterPromo() {
    this.API._get(`stores`, []).subscribe((res: any) => {
      console.log(res)

      if(res.result == 'ok') {
        this.stores = res.data;
        /*this.resultados = res.data.data;
        this.total = res.data.total * 12;*/
      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.Helper.show('Ocurrio un error al obtener los datos', { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      console.warn('search Reduce -> ', e);
    });
  }


  clear(): void {
    this.Filtros = this.emptyFilters();
    this.inputSend = '';
    this.listar();
  }

  emptyFilters(): Filtros {
    return {
      sku: null,
      id: null,
      description: null,
      page: 1,
      page_size: 100,
      tienda: null
    };
  }

  pagination(event: any) {
    this.Filtros.page = event;
    this.listar();
  }

  InputSerach() {
    if(this.tipo == 'SKU') {
      this.Filtros.sku = this.inputSend;
      this.Filtros.id = null;
      this.Filtros.description = null
    } else if (this.tipo == 'Evento'){
      this.Filtros.description =this.inputSend
      this.Filtros.sku = null;
      this.Filtros.id = null;
    } else {
      this.Filtros.sku = null;
      this.Filtros.description = null;
      this.Filtros.id = this.inputSend;
    }
  }

}
